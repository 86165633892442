@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.text_block {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-items: stretch;
   gap: vw(32px, 320px);

   margin-bottom: 120px;

   @media screen and (min-width: 500px) {
      gap: vw(40px, 744px);
   }

   @media screen and (min-width: 920px) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: vw(80px);
   }

   @media screen and (min-width: 920px) {
      gap: 80px;
   }

   &__wrapper_text {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: vw(14px, 320px);

      @media screen and (min-width: 525px) {
         min-width: 100%;
         gap: vw(14px, 744px);
      }
      @media screen and (min-width: 920px) {
         max-width: vw(700px);
         gap: vw(14px);
      }
      @media screen and (min-width: 1440px) {
         width: 700px;
         gap: 14px;
      }
   }
   &__content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 26px;

      color: $text;

      &_picture {
         position: relative;

         display: flex;
         flex-direction: column;
         flex-wrap: nowrap;
         align-items: center;
         justify-content: center;

         min-width: 100%;

         @media screen and (min-width: 920px) {
            min-width: vw(500px);
         }

         @media screen and (min-width: 1440px) {
            min-width: 500px;
         }

         &_left {
            @media screen and (min-width: 920px) {
               transform: rotate(-2deg) !important;
            }
         }
      }
   }
   &__title {
      @extend .m-h2;

      @media screen and (min-width: 525px) {
         font-size: vw(36px, 744px);
         line-height: vw(43px, 744px);
      }
      @media screen and (min-width: 920px) {
         font-size: vw(36px);
         line-height: vw(43px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 36px;
         line-height: 43px;
      }
   }
   &__text {
      @extend .m-p;

      @media screen and (min-width: 525px) {
         font-size: vw(24px, 744px);
         line-height: vw(32px, 744px);
      }
      @media screen and (min-width: 920px) {
         font-size: vw(24px);
         line-height: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 24px;
         line-height: 32px;
      }
   }
}

.picture {
   width: vw(290px, 320px);
   height: vw(232px, 320px);
   border-radius: 30px;

   z-index: 1;

   transition: transform ease-in-out 0.3s;

   @media screen and (min-width: 525px) {
      width: vw(500px, 744px);
      height: vw(400px, 744px);
   }
   @media screen and (min-width: 920px) {
      width: vw(500px);
      height: vw(400px);
      transform: rotate(2deg);
   }
   @media screen and (min-width: 1440px) {
      width: 500px;
      height: 400px;
   }

   &_left {
      @media screen and (min-width: 920px) {
         transform: rotate(-2deg) !important;
      }
   }

   &_blur {
      position: absolute;
      top: vw(20px, 320px);
      left: vw(14px, 320px);
      opacity: 0.5;
      filter: blur(vw(20px, 320px));
      z-index: -1;

      @media screen and (min-width: 525px) {
         top: vw(20px, 744px);
         left: vw(128px, 744px);
         filter: blur(vw(20px, 744px));
      }

      @media screen and (min-width: 920px) {
         top: vw(20px);
         left: vw(14px);
         filter: blur(vw(20px));
      }

      @media screen and (min-width: 1440px) {
         top: 20px;
         left: 14px;
         filter: blur(20px);
      }
   }
}

@supports (mask-image: paint(smooth-corners)) {
   .picture.is-loaded {
      border-radius: 0;
      mask-image: paint(smooth-corners);
      --smooth-corners: 4;
   }
}
