@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../helpers.scss";

.stepped {
   position: relative;
   min-height: vw(460px, 320px);
   padding-top: vw(80px, 320px);

   margin-bottom: 100px;

   @media screen and (min-width: 525px) {
      padding-left: vw(50px, 744px);
      padding-right: vw(50px, 744px);
      min-height: vw(492px, 744px);
      padding-top: vw(80px, 744px);
   }

   @media screen and (min-width: 920px) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      min-height: auto;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: vw(80px);
      gap: vw(26px);
   }

   @media screen and (min-width: 1440px) {
      max-height: 349px;
      padding-top: 80px;
   }

   &__title {
      position: absolute;
      top: 0px;
      left: 0px;

      @extend .m-h2;

      @media screen and (min-width: 525px) {
         font-size: vw(36px, 744px);
         line-height: vw(43px, 744px);
      }
      @media screen and (min-width: 920px) {
         font-size: vw(36px);
         line-height: vw(43px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 36px;
         line-height: 43px;
      }
   }

   &__step {
      background-color: inherit;

      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;

      min-width: vw(290px, 320px);
      width: 100%;

      padding-bottom: vw(32px, 320px);

      @media screen and (min-width: 525px) {
         padding-bottom: vw(32px, 744px);
         min-width: vw(600px, 744px);
      }

      @media screen and (min-width: 920px) {
         flex-direction: column-reverse;
         padding-bottom: 0px;
         min-width: vw(300px);
         max-width: vw(300px);
      }

      @media screen and (min-width: 1440px) {
         min-width: 300px;
         max-width: 300px;
      }
   }

   &__line {
      position: absolute;
      width: vw(47px, 320px);
      height: vw(320px, 320px);
      top: vw(106px, 320px);
      left: vw(20px, 320px);

      @media screen and (min-width: 525px) {
         width: vw(60px, 744px);
         height: vw(492px, 744px);

         top: vw(136px, 744px);
         left: vw(76px, 744px);
      }
      @media screen and (min-width: 920px) {
         display: none;
      }
   }

   &__horizontal_line {
      position: absolute;
      height: vw(66px);
      top: vw(130px);
      left: vw(147px);
      width: vw(987px);

      @media screen and (max-width: 920px) {
         display: none;
      }

      @media screen and (min-width: 1440px) {
         height: 66px;
         top: 130px;
         left: 147px;
         width: 987px;
      }
   }

   &__point {
      @extend .m-point;
      width: vw(70px, 320px);
      height: vw(70px, 320px);
      background: #ffffff;
      box-shadow: 0px vw(15px, 320px) vw(30px, 320px) rgba(0, 0, 0, 0.03);
      color: $accent;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      z-index: 3;
      @media screen and (min-width: 525px) {
         width: vw(129px, 744px);
         height: vw(129px, 744px);
         font-size: vw(32px, 744px);
         box-shadow: 0px vw(15px, 744px) vw(30px, 744px) rgba(0, 0, 0, 0.03);
      }
      @media screen and (min-width: 920px) {
         width: vw(129px);
         height: vw(129px);
         font-size: vw(48px);
         box-shadow: 0px vw(15px) vw(30px) rgba(0, 0, 0, 0.03);
      }

      @media screen and (min-width: 1440px) {
         width: 129px;
         height: 129px;
         font-size: 48px;
         box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.03);
      }
   }

   &__content {
      @extend .m-p;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding-left: vw(14px, 320px);

      height: vw(70px, 320px);
      text-align: left;
      @media screen and (min-width: 525px) {
         padding-left: vw(36px, 744px);
         font-size: vw(24px, 744px);
         line-height: vw(31px, 744px);
         height: vw(62px, 744px);
      }
      @media screen and (min-width: 920px) {
         text-align: center;
         font-size: vw(24px);
         line-height: vw(31px);
         height: vw(100px);
         padding-left: 0px;
         padding-top: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         font-size: 24px;
         line-height: 31px;
         height: 100px;
         padding-top: 32px;
      }
   }
}
