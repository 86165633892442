@import "../helpers.scss";

.font-name {
   font-family: "Montserrat";
}

.d-h1-offer {
   @extend .font-name;
   font-style: normal;
   font-size: 48px;
   font-weight: 600;
   line-height: 59px;
   letter-spacing: 0em;
}

.d-h2 {
   @extend .font-name;
   font-style: normal;
   font-size: 36px;
   font-weight: 600;
   line-height: 44px;
   letter-spacing: 0em;
}

.d-h2-advantages {
   @extend .font-name;
   font-style: normal;
   font-weight: 500;
   font-size: 20px;
   line-height: 22px;
   letter-spacing: 0em;
}

.d-h2-footer {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 16px;
   letter-spacing: 0em;
}

.d-p {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: 24px;
   line-height: 31px;
   letter-spacing: 0em;
}

.d-p-offer {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: 32px;
   line-height: 39px;
   letter-spacing: 0em;
}

.d-p-footer {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 14px;
   letter-spacing: 0em;
}

.d-p-footer-copyright {
   @extend .font-name;
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 16px;
   letter-spacing: 0em;
}

.d-p-advantages {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 18px;
   letter-spacing: 0em;
}

.m-button {
   @extend .font-name;
   font-style: normal;
   font-weight: 600;
   font-size: vw(12px, 320px);
   line-height: vw(15px, 320px);
   letter-spacing: 0em;
}

.m-button-link {
   @extend .font-name;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 15px;
   letter-spacing: 0em;
}

.m-button-icon {
   @extend .font-name;
   font-style: normal;
   font-weight: 500;
   font-size: vw(10px, 320px);
   line-height: vw(11px, 320px);
   letter-spacing: 0em;
}

.m-h2 {
   @extend .font-name;
   font-style: normal;
   font-weight: 600;
   font-size: vw(24px, 320px);
   line-height: vw(29px, 320px);
   letter-spacing: 0em;
}

.m-h2-advantages {
   @extend .font-name;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 18px;
   letter-spacing: 0em;
}

.m-p {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: vw(16px, 320px);
   line-height: vw(21px, 320px);
   letter-spacing: 0em;
}

.m-p-advantages {
   @extend .font-name;
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 13px;
   letter-spacing: 0em;
}

.m-p {
   @extend .font-name;
   font-size: vw(16px, 320px);
   font-weight: 400;
   line-height: vw(21px, 320px);
   letter-spacing: 0em;
   text-align: left;
}

.m-point {
   @extend .font-name;
   font-size: vw(32px, 320px);
   font-weight: 900;
   line-height: vw(39px, 320px);
   letter-spacing: 0em;
   text-align: center;
}

.m-foote-content {
   @extend .font-name;
   font-size: vw(14px, 320px);
   font-weight: 400;
   line-height: vw(17px, 320px);
   letter-spacing: 0em;
   text-align: left;
}

.m-p-copirite {
   @extend .font-name;
   font-size: vw(12px, 320px);
   font-weight: 500;
   line-height: vw(16px, 320px);
   letter-spacing: 0em;
   text-align: center;
}

.m-h2-footer {
   @extend .font-name;
   font-size: vw(16px, 320px);
   font-weight: 400;
   line-height: vw(16px, 320px);
   letter-spacing: 0em;
   text-align: center;
}
