@import "../../helpers.scss";

.landing {
   &Advantages {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom: vw(60px, 320px);

      @media screen and (min-width: 500px) {
         flex-direction: row;
         flex-wrap: wrap;
         height: auto;
         margin-bottom: vw(80px, 744px);
      }

      @media screen and (min-width: 920px) {
         flex-wrap: nowrap;
         height: vw(300px);
         margin-bottom: vw(120px);
      }

      @media screen and (min-width: 1440px) {
         flex-wrap: nowrap;
         height: 300px;
         margin-bottom: 120px;
      }
   }
}
