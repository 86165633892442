@import "../../styles/colors.scss";
@import "../../helpers.scss";

.menu {
   position: fixed;
   z-index: 5;
   bottom: 0px;

   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   flex-wrap: nowrap;
   flex-direction: row;
   gap: vw(45px, 320px);

   height: vw(60px, 320px);
   width: 100%;
   max-width: 525px;

   background-color: $base;
   padding: 0 20px;

   @media screen and (min-width: 525px) {
      display: none;
   }

   &__about {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;

      width: vw(120px, 320px);
      height: 100%;
      &_icon {
         width: vw(28px, 320px);
         height: vw(29px, 320px);
      }
   }

   &__action {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: stretch;

      width: 100%;
      height: 100%;
   }
}
