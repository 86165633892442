@import "../../helpers.scss";

.mb_64 {
   margin-bottom: vw(64px, 320px);

   @media screen and (min-width: 525px) {
      margin-bottom: vw(64px, 744px);
   }

   @media screen and (min-width: 920px) {
      margin-bottom: vw(64px);
   }

   @media screen and (min-width: 1440px) {
      margin-bottom: 64px;
   }
}

.mb_0 {
   margin-bottom: 0px;
}

.pt_24 {
   padding-top: vw(24px, 320px);

   @media screen and (min-width: 525px) {
      padding-top: vw(24px, 744px);
   }

   @media screen and (min-width: 920px) {
      padding-top: vw(24px);
   }

   @media screen and (min-width: 1440px) {
      padding-top: 24px;
   }
}
