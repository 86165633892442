@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../helpers.scss";

.button {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;

   min-height: vw(42px, 320px);
   max-width: vw(226px, 320px);

   padding: 0px vw(20px, 320px);

   background: $accent;

   border: none;
   border-radius: 8px;

   cursor: pointer;

   transition: background-color ease-in-out 300ms;

   &:hover {
      background-color: $hover;
   }

   @media screen and (min-width: 525px) {
      min-height: vw(42px, 744px);
      max-width: vw(230px, 744px);
      padding: 0px vw(30px, 744px);
   }

   @media screen and (min-width: 920px) {
      min-height: vw(42px);
      max-width: vw(230px);
      padding: 0px vw(30px);
   }

   @media screen and (min-width: 1440px) {
      min-height: 42px;
      max-width: 230px;
      padding: 0px 30px;
   }

   p {
      padding: 0px;
      @extend .m-button;
      color: $text-light;

      @media screen and (min-width: 525px) {
         font-size: vw(16px, 744px);
         line-height: vw(20px, 744px);
      }

      @media screen and (min-width: 920px) {
         font-size: vw(16px);
         line-height: vw(20px);
      }

      @media screen and (min-width: 1440px) {
         font-size: 16px;
         line-height: 20px;
      }
   }
}
