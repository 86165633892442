@import "../../helpers.scss";

.banner {
   border-radius: vw(30px, 320px) vw(30px, 320px) 0px 0px;
   width: vw(290px, 320px);
   height: vw(116px, 320px);

   @media screen and (min-width: 500px) {
      border-radius: vw(30px, 744px) vw(30px, 744px) 0px 0px;
      width: vw(704px, 744px);
      height: vw(264px, 744px);
   }

   @media screen and (min-width: 920px) {
      border-radius: vw(30px) vw(30px) 0px 0px;
      width: vw(1280px);
      height: vw(500px);
   }

   @media screen and (min-width: 1440px) {
      border-radius: 30px 30px 0px 0px;
      width: 1280px;
      height: 500px;
      object-fit: cover;
   }
}

.banner_rounded {
   border-radius: vw(15px, 320px);

   @media screen and (min-width: 500px) {
      border-radius: vw(30px, 744px);
   }

   @media screen and (min-width: 920px) {
      border-radius: vw(30px);
   }

   @media screen and (min-width: 1440px) {
      border-radius: 30px;
   }
}
