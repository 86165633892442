.errorPage {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
   padding: 0px 16px;
   gap: 24px;

   @media screen and (min-width: 768px) {
      min-width: 100vmax;
      min-height: 100vh;
      padding: 0px;
   }

   &__title {
      font-size: 128px;
      font-weight: 900;
      line-height: 128px;
      letter-spacing: 0em;
      text-align: center;

      margin: 24px 0px;
   }
   &__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;

      max-width: 420px;
      min-width: 280px;
   }
   &__linkBack {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-decoration: none;

      color: #fff;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 30px;

      height: 42px;

      background: #0070f3;
      border-radius: 8px;
   }
}
