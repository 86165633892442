@import "../../styles/fonts.scss";
@import "../../helpers.scss";

.button {
   &__icon {
      background-color: transparent;
      border: none;
      padding: 0px;
      margin: 0px;

      width: 100%;
      height: 100%;

      cursor: pointer;
      span {
         display: flex;
         flex-direction: column;
         flex-wrap: nowrap;
         justify-content: center;
         align-items: center;
         p {
            @extend .m-button-icon;
         }
      }
   }
}
