@import "../../helpers.scss";
@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.map {
   position: relative;
   width: 100%;
   height: vw(267px, 320px);
   background-color: $text;
   margin-bottom: vw(435px, 320px);
   border-radius: vw(30px, 320px);

   @media screen and (min-width: 525px) {
      height: vw(756px, 744px);
      margin-bottom: 0px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      border-radius: vw(30px, 744px);
      padding: vw(40px, 744px);
   }
   @media screen and (min-width: 920px) {
      height: vw(500px);
      border-radius: vw(30px);
      align-items: center;
      justify-content: flex-end;
      padding: vw(40px);
   }
   @media screen and (min-width: 1440px) {
      height: 500px;
      border-radius: 30px;
      padding: 40px;
   }

   &__footer {
      position: absolute;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      gap: vw(20px, 320px);
      top: vw(282px, 320px);
      width: 100%;
      padding: vw(30px, 320px) vw(10px, 320px);
      box-shadow: 0px vw(15px, 320px) vw(30px, 320px) rgba(0, 0, 0, 0.03);
      border-radius: 30px;
      min-height: vw(420px, 320px);
      background-color: $base;
      @media screen and (min-width: 525px) {
         position: relative;
         gap: vw(20px, 744px);
         top: 0px;
         width: vw(350px, 744px);
         padding: vw(30px, 744px) vw(10px, 744px);
         box-shadow: 0px vw(15px, 744px) vw(30px, 744px) rgba(0, 0, 0, 0.03);
         border-radius: 10px;
         min-height: vw(420px, 744px);
      }
      @media screen and (min-width: 920px) {
         gap: vw(20px);
         top: 0px;
         width: vw(350px);
         padding: vw(30px) vw(10px);
         box-shadow: 0px vw(15px) vw(30px) rgba(0, 0, 0, 0.03);
         min-height: vw(420px);
      }
      @media screen and (min-width: 1440px) {
         gap: 20px;
         top: 0px;
         width: 350px;
         padding: 30px 10px;
         box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.03);
         min-height: 420px;
      }
   }
   &__head,
   &__developer {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      svg {
         width: vw(141px, 320px);
         height: vw(32px, 320px);
         @media screen and (min-width: 525px) {
            width: vw(141px, 744px);
            height: vw(32px, 744px);
         }
         @media screen and (min-width: 920px) {
            width: vw(141px);
            height: vw(32px);
         }
         @media screen and (min-width: 1440px) {
            width: 141px;
            height: 32px;
         }
      }
   }

   &__head {
      @extend .m-h2-footer;
      @media screen and (min-width: 525px) {
         font-size: vw(16px, 744px);
         line-height: vw(16px, 744px);
      }
      @media screen and (min-width: 920px) {
         font-size: vw(16px);
         line-height: vw(16px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 16px;
         line-height: 16px;
      }
   }

   &__developer {
      @extend .m-p-copirite;
      gap: vw(4px, 320px);

      @media screen and (min-width: 525px) {
         font-size: vw(12px, 744px);
         line-height: vw(16px, 744px);
         gap: vw(4px, 744px);
      }
      @media screen and (min-width: 920px) {
         font-size: vw(12px);
         line-height: vw(16px);
         gap: vw(4px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 12px;
         line-height: 16px;
         gap: 4px;
      }

      &_police {
         color: $accent !important;
      }

      a {
         text-decoration: none;
         color: $text;
         &:link,
         &:visited {
            text-decoration: none;
            cursor: pointer;
         }
      }
   }
   &__content {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      gap: vw(20px, 320px);
      @media screen and (min-width: 525px) {
         gap: vw(20px, 744px);
      }
      @media screen and (min-width: 920px) {
         gap: vw(20px);
      }
      @media screen and (min-width: 1440px) {
         gap: 20px;
      }
   }
   &__address {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      gap: 6px;
      width: 100%;
      font-style: normal;
      @media screen and (min-width: 525px) {
         gap: vw(6px, 744px);
      }
      @media screen and (min-width: 920px) {
         gap: vw(6px);
      }
      @media screen and (min-width: 1440px) {
         gap: 6px;
      }
      p,
      span,
      a {
         @extend .m-foote-content;

         @media screen and (min-width: 525px) {
            font-size: vw(14px, 744px);
            line-height: vw(17px, 744px);
         }
         @media screen and (min-width: 920px) {
            font-size: vw(14px);
            line-height: vw(17px);
         }
         @media screen and (min-width: 1440px) {
            font-size: 14px;
            line-height: 17px;
         }
      }

      a {
         text-decoration: none;
         color: $text;
      }
   }
   &__social {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: vw(26px, 320px);
      min-height: 100%;
      @media screen and (min-width: 525px) {
         gap: vw(26px, 744px);
      }
      @media screen and (min-width: 920px) {
         gap: vw(26px);
      }
      @media screen and (min-width: 1440px) {
         gap: 26px;
      }
   }
   &__social_icon {
      width: vw(20px, 320px);
      height: auto;
      @media screen and (min-width: 525px) {
         width: vw(20px, 744px);
      }
      @media screen and (min-width: 920px) {
         width: vw(20px);
      }
      @media screen and (min-width: 1440px) {
         width: 20px;
      }
   }
}
