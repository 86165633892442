@import "../../helpers.scss";
@import "../../styles/fonts.scss";

.text_block {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-items: stretch;
   padding-top: vw(80px, 320px);
   @media screen and (min-width: 525px) {
      padding-top: vw(64px, 744px);
   }
   @media screen and (min-width: 920px) {
      padding-top: vw(64px);
   }
   @media screen and (min-width: 1440px) {
      padding-top: 64px;
   }
   &__title {
      @extend .m-h2;

      @media screen and (min-width: 525px) {
         font-size: vw(36px, 744px);
         line-height: vw(43px, 744px);
      }
      @media screen and (min-width: 920px) {
         font-size: vw(36px);
         line-height: vw(43px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 36px;
         line-height: 43px;
      }
   }
   &__content {
      @extend .m-p;
      @media screen and (min-width: 525px) {
         font-size: vw(24px, 744px);
         line-height: vw(31px, 744px);
      }

      @media screen and (min-width: 920px) {
         font-size: vw(24px);
         line-height: vw(31px);
      }

      @media screen and (min-width: 1440px) {
         font-size: 24px;
         line-height: 31px;
      }
   }
   &__action {
      @extend .m-button-link;
      width: 100%;
      @media screen and (min-width: 525px) {
         font-size: vw(16px, 744px);
         line-height: vw(20px, 744px);
         display: block;
      }
      @media screen and (min-width: 920px) {
         font-size: vw(16px);
         line-height: vw(20px);
      }
      @media screen and (min-width: 1440px) {
         font-size: 16px;
         line-height: 20px;
      }
   }
}

.mb_28 {
   margin-bottom: vw(28px, 320px);
   @media screen and (min-width: 525px) {
      margin-bottom: vw(28px, 744px);
   }

   @media screen and (min-width: 920px) {
      margin-bottom: vw(28px);
   }

   @media screen and (min-width: 1440px) {
      margin-bottom: 28px;
   }
}

.mb_24 {
   margin-bottom: vw(24px, 320px);
   @media screen and (min-width: 525px) {
      margin-bottom: vw(24px, 744px);
   }

   @media screen and (min-width: 920px) {
      margin-bottom: vw(24px);
   }

   @media screen and (min-width: 1440px) {
      margin-bottom: 24px;
   }
}
