@import "../../helpers.scss";
@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.advantagesCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: vw(250px, 320px);
    height: vw(110px, 320px);

    gap: vw(6px, 320px);

    @media screen and (min-width: 500px) {
        width: vw(300px, 744px);
        height: vw(150px, 744px);

        gap: vw(20px, 744px);
    }

    @media screen and (min-width: 920px) {
        width: vw(300px);
        height: vw(150px);

        gap: vw(20px);
    }

    @media screen and (min-width: 1440px) {
        width: 300px;
        height: 150px;

        gap: 20px;
    }

    &_icon {
        max-width: vw(48px, 320px);
        min-width: vw(48px, 320px);
        max-height: vw(48px, 320px);
        min-height: vw(48px, 320px);

        @media screen and (min-width: 500px) {
            max-width: vw(64px, 744px);
            min-width: vw(64px, 744px);
            max-height: vw(64px, 744px);
            min-height: vw(64px, 744px);
        }

        @media screen and (min-width: 920px) {
            max-width: vw(64px);
            min-width: vw(64px);
            max-height: vw(64px);
            min-height: vw(64px);
        }

        @media screen and (min-width: 1440px) {
            max-width: 64px;
            min-width: 64px;
            max-height: 64px;
            min-height: 64px;
        }   
    }

    &_text {
        gap: vw(6px, 320px);
        @media screen and (min-width: 500px) {
            gap: vw(6px, 744px);
        }
    
        @media screen and (min-width: 920px) {
            gap: vw(6px);
        }
    
        @media screen and (min-width: 1440px) {
            gap: 6px;
        }
    }

    &_title {
        @extend .m-h2-advantages;
        text-align: center;
        color: $text;

        @media screen and (min-width: 500px) {
            font-size: vw(20px, 744px);
            line-height: vw(22px, 744px);
        }

        @media screen and (min-width: 920px) {
            font-size: vw(20px);
            line-height: vw(22px);
        }

        @media screen and (min-width: 1440px) {
            font-size: 20px;
            line-height: 22px;
        }
    }

    &_description {
        @extend .m-p-advantages;
        text-align: center;
        color: $text;

        @media screen and (min-width: 500px) {
            font-size: vw(16px, 744px);
            line-height: vw(18px, 744px);
        }

        @media screen and (min-width: 920px) {
            font-size: vw(16px);
            line-height: vw(18px);
        }

        @media screen and (min-width: 1440px) {
            font-size: 16px;
            line-height: 18px;
        }
    }
}