@import "../helpers.scss";

.wrapper {
   padding-top: 0px;
   padding-bottom: 100px;
   margin: 0px auto;
   width: calc(100vw - 30px);

   @media screen and (min-width: 500px) {
      width: calc(100vw - 40px);
      padding-left: 0px;
      padding-right: 0px;
   }

   @media screen and (min-width: 920px) {
      width: vw(1440px);
      padding-left: vw(80px);
      padding-right: vw(80px);
   }

   @media screen and (min-width: 1440px) {
      width: 1440px;
      padding-left: 80px;
      padding-right: 80px;
   }
}
