@import "../../styles/colors.scss";
@import "../../helpers.scss";

.underBannerContainer {
    background: $base;
    box-shadow: $base-shadow;
    
    padding: vw(24px, 320px) vw(40px, 320px);
    border-radius: 0px 0px vw(30px, 320px) vw(30px, 320px);

    @media screen and (min-width: 500px) {
        padding: vw(24px, 744px) vw(40px, 744px);
        border-radius: 0px 0px vw(30px, 744px) vw(30px, 744px);
    }

    @media screen and (min-width: 920px) {
        padding: vw(24px) vw(40px);
        border-radius: 0px 0px vw(30px) vw(30px);
    }

    @media screen and (min-width: 1440px) {
        padding: 24px 40px;
        border-radius: 0px 0px 30px 30px;
    }
}