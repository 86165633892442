@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

@import "../../helpers.scss";

.header {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;

   height: vw(80px, 320px);
   width: 100vw;

   padding: 0px 15px;

   background-color: $base;

   @media screen and (min-width: 500px) {
      padding: 0px vw(20px, 744px);
      height: vw(100px, 744px);
   }

   @media screen and (min-width: 920px) {
      padding: 0px vw(80px);
      height: vw(100px);
   }

   @media screen and (min-width: 1440px) {
      padding: 0px 80px;
      width: 1440px;
      margin: 0px calc(50vw - 720px);
      height: 100px;
   }

   &__logo {
      width: 139px;
      height: 32px;
   }
   &__navigation {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: 0px;
      @media screen and (min-width: 500px) {
         gap: vw(30px, 744px);
      }
      @media screen and (min-width: 920px) {
         gap: vw(60px);
      }
      &_item {
         @extend .m-button-link;
         text-decoration: none;
         color: $text;
         display: none;

         &Show {
            display: block;
         }
         @media screen and (min-width: 525px) {
            font-size: vw(16px, 744px);
            line-height: vw(20px, 744px);
            display: block;
         }
         @media screen and (min-width: 920px) {
            font-size: vw(16px);
            line-height: vw(20px);
         }
         @media screen and (min-width: 1440px) {
            font-size: 16px;
            line-height: 20px;
         }
      }
   }
}
