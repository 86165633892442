@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;900&display=swap");
@import "./helpers.scss";
@import "./styles/colors.scss";

* {
   margin: 0px;
   padding: 0px;
   box-sizing: border-box;
   object-fit: cover;
}

a:focus,
button:focus {
   outline: none;
   color: $hover;
}

body {
   font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
}
